import React from "react"
import { Col, Container, Jumbotron, Row } from "reactstrap"
import ReactHtmlParser from "react-html-parser"
import LinkItem from "./LinkItem"
import classNames from "classnames"
import Asset from "./Asset"

enum AssetPosition {
  LEFT = "left",
  TOP = "top",
  BOTTOM = "bottom",
  RIGHT = "right",
}

interface TranslationData {
  title: string
  asset: {
    localFile: {
      publicURL: string
    } | null
    mimeType: string
    url: string
  } | null
  assetPosition: AssetPosition
  description: {
    html: string
  }
  displayBackground: boolean
  particleEffect: boolean
  titleBackgroundEffect: boolean
  linkItems: any
}

type PageHeaderProps = {
  translation: TranslationData
  defaultTranslation: TranslationData
}

const NewPageHeader = ({
  translation,
  defaultTranslation,
}: PageHeaderProps) => {
  const assetContent = <Asset asset={defaultTranslation.asset} />

  const parsedDescription = translation.description
    ? ReactHtmlParser(translation.description.html)
    : null

  const renderLinks = () => {
    return translation.linkItems.map((linkItemData, index) => {
      return (
        <LinkItem
          linkItemData={linkItemData}
          defaultLinkItemData={defaultTranslation.linkItems[index]}
        />
      )
    })
  }

  const textContent = (
    <div
      className={classNames("PageHeader__container", {
        "PageHeader__container--padding-left":
          !!defaultTranslation?.asset?.localFile?.publicURL &&
          defaultTranslation.assetPosition === AssetPosition.RIGHT,
        "PageHeader__container--text-center": [
          AssetPosition.BOTTOM,
          AssetPosition.TOP,
        ].includes(defaultTranslation.assetPosition),
      })}
    >
      <h1
        className={classNames("PageHeader__title", {
          "title-background-effect": defaultTranslation.titleBackgroundEffect,
        })}
      >
        {translation.title}
      </h1>
      {parsedDescription}
      <div className="d-flex justify-content-center">
        <div className="PageHeader__container__link-items">{renderLinks()}</div>
      </div>
    </div>
  )

  if (!defaultTranslation.asset) {
    return (
      <Container
        className={classNames({
          "text-left": defaultTranslation.assetPosition === AssetPosition.LEFT,
          "text-right":
            defaultTranslation.assetPosition === AssetPosition.RIGHT,
          "text-center": [AssetPosition.TOP, AssetPosition.BOTTOM].includes(
            defaultTranslation.assetPosition
          ),
        })}
      >
        {textContent}
      </Container>
    )
  }

  const renderContent = (displayParticles: boolean) => {
    switch (defaultTranslation.assetPosition) {
      case AssetPosition.LEFT:
        return (
          <Row className="g-0 h-100 justify-content-center align-items-center">
            <Col
              md={{ size: 5, offset: 1 }}
              className={classNames("PageHeader__image-container", {
                ParticleHeader: displayParticles,
              })}
            >
              {assetContent}
              {displayParticles && (
                <div className="ParticleHeader__particle-bottom-right" />
              )}
            </Col>
            <Col
              sm={12}
              md={{ size: 10, offset: 1 }}
              lg={{ size: 5 }}
              className={classNames(
                "h-100 justify-content-center align-items-center",
                {
                  ParticleHeader: displayParticles,
                }
              )}
            >
              {textContent}
              {displayParticles && (
                <>
                  <div className="ParticleHeader__particle-top-left" />
                  <div className="ParticleHeader__particle-top" />
                </>
              )}
            </Col>
          </Row>
        )
      case AssetPosition.RIGHT:
        return (
          <Row className="g-0 h-100 justify-content-center align-items-center">
            <Col
              md={12}
              sm={12}
              lg={6}
              className={classNames({
                ParticleHeader: displayParticles,
              })}
            >
              {textContent}
              {displayParticles && (
                <>
                  <div className="ParticleHeader__particle-top" />
                  <div className="ParticleHeader__particle-top-right" />
                </>
              )}
            </Col>
            <Col
              md={5}
              className={classNames("PageHeader__image-container", {
                ParticleHeader: displayParticles,
              })}
            >
              {assetContent}
              {displayParticles && (
                <div className="ParticleHeader__particle-bottom-right" />
              )}
            </Col>
          </Row>
        )
      case AssetPosition.BOTTOM:
        return (
          <Row className="g-0 overflow-hidden-row">
            <Col
              md={{ size: 10, offset: 1 }}
              xl={{ size: 6, offset: 3 }}
              className={classNames("mb-4", {
                ParticleHeader: displayParticles,
              })}
            >
              {textContent}
              {displayParticles && (
                <>
                  <div className="ParticleHeader__particle-top-left" />
                  <div className="ParticleHeader__particle-top-right" />
                  <div className="ParticleHeader__particle-top" />
                </>
              )}
            </Col>
            <Col
              md={{ size: 10, offset: 1 }}
              xl={{ size: 6, offset: 3 }}
              className={classNames("ParticleHeader")}
            >
              {assetContent}
              {displayParticles && (
                <div className="ParticleHeader__particle-bottom-right" />
              )}
            </Col>
          </Row>
        )
      case AssetPosition.TOP:
        return (
          <Row className="g-0 overflow-hidden-row">
            <Col
              xl={{ size: 6, offset: 3 }}
              className={classNames("mb-4", {
                ParticleHeader: displayParticles,
              })}
            >
              {assetContent}
              {displayParticles && (
                <>
                  <div className="ParticleHeader__particle-top-left" />
                  <div className="ParticleHeader__particle-top-right" />
                  <div className="ParticleHeader__particle-top" />
                </>
              )}
            </Col>
            <Col
              xl={{ size: 6, offset: 3 }}
              className={classNames("ParticleHeader")}
            >
              {textContent}
              {displayParticles && (
                <div className="ParticleHeader__particle-bottom-right" />
              )}
            </Col>
          </Row>
        )
    }
  }

  if (defaultTranslation.displayBackground) {
    return (
      <Jumbotron className="PageHeader">
        <Row className="h-100 justify-content-center align-items-center g-0">
          {renderContent(false)}
        </Row>
      </Jumbotron>
    )
  } else {
    return renderContent(defaultTranslation.particleEffect)
  }
}

export default NewPageHeader
