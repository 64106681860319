import { graphql } from "gatsby"
import React from "react"
import { Container } from "reactstrap"
import Layout from "../components/Layout"
import NewPageHeader from "../components/NewPageHeader"
import Seo from "../components/seo"
import { LanguageContext } from "../context/LanguageContext"
import { sectionRender } from "../helpers/sectionRender"

const PageWithSections = ({ data }) => {
  const { language } = React.useContext(LanguageContext)

  const translationObject = data.allGraphCmsPage.nodes.find(
    obj => obj.locale === language
  )
  const defaultObject = data.allGraphCmsPage.nodes[0]

  const headerTranslation = translationObject.header
  const defaultHeaderData = defaultObject.header

  return (
    <Layout>
      <Seo title={translationObject.title} />
      {translationObject.displayHeader && headerTranslation && (
        <NewPageHeader
          translation={headerTranslation}
          defaultTranslation={defaultHeaderData}
        />
      )}
      <Container>{sectionRender(translationObject, defaultObject)}</Container>
    </Layout>
  )
}

export const query = graphql`
  query TemplateQuery($slug: String!) {
    allGraphCmsPage(filter: { slug: { eq: $slug } }) {
      nodes {
        locale
        title
        isEvenColored
        displayHeader
        header {
          title
          description {
            html
          }
          asset {
            mimeType
            localFile {
              publicURL
            }
            url
          }
          assetPosition
          displayOnlyTitle
          particleEffect
          displayBackground
          titleBackgroundEffect
          linkItems {
            text
            link
            isInternal
            buttonTheme
            outline
            type
            image {
              localFile {
                publicURL
              }
            }
            imagePosition
          }
        }
        pageSections {
          title
          titleBackgroundEffect
          textPosition
          subTitle
          type
          isHidden
          imageHaveBackground
          headerImage {
            localFile {
              publicURL
            }
          }
          asset {
            url
            localFile {
              publicURL
            }
          }
          carouselSlideTime
          carouselAssets {
            mimeType
            url
            localFile {
              publicURL
            }
          }
          description {
            html
          }
          imagePosition
          linkItems {
            link
            type
            text
            isHidden
            image {
              localFile {
                publicURL
              }
            }
            imagePosition
          }
          cardItems {
            title
            description {
              html
            }
            asset {
              localFile {
                publicURL
              }
            }
          }
          leftDescription {
            html
          }
          rightDescription {
            html
          }
          imagesWithLink {
            id
            linkItem {
              link
              type
              text
              isHidden
            }
            asset {
              localFile {
                publicURL
              }
            }
          }
          graspTeamMemberGroups {
            id
            title
            subTitle
            displayTitle
            graspTeamMembers {
              id
              name
              role
              email
              description {
                html
              }
              photo {
                localFile {
                  publicURL
                }
              }
            }
          }
          logos {
            width
            height
            localFile {
              publicURL
            }
          }
          orderImages {
            localFile {
              publicURL
            }
          }
          downloadableElement {
            image {
              localFile {
                publicURL
                id
              }
            }
            linkItem {
              link
              type
              text
            }
            style
            title
          }
          resizableAssetsWithContent {
            id
            desktopItemWidth
            tabletItemWidth
            mobileItemWidth
            highlighted
            assetWithContent {
              title
              description {
                html
              }
              asset {
                localFile {
                  publicURL
                }
                url
                mimeType
              }
              linkItem {
                text
                link
                isInternal
                buttonTheme
                outline
                type
                image {
                  localFile {
                    publicURL
                  }
                }
                imagePosition
              }
            }
          }
        }
      }
    }
  }
`

export default PageWithSections
